import React from 'react';
import getURLParameters from '../../helpers/getURLParams';
import getPriceData from '../../helpers/getPriceData';
import countriesAndStates from '../../testing/mocks/countriesAndStates';
import { initializeFormValidation } from '../../helpers/forms/form-validation';
import initializeLocations, { getStates } from '../../helpers/forms/location-form';
import redirect from '../../components/redirect/redirect';
import message from '../../components/message/message';
import ClipLoader from 'react-spinners/ClipLoader'
import Row from 'react-bootstrap/Row'
import { modelRequest } from '@stem-sims/nexus'


class PurchaseOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            readingLicenses: 0,
            mathLicenses: 0,
            erLicenses: 0,
            discountCode: null,
            price: "~~~",
            loading: true,
            submitting: false,

            countriesOptions: []
        }
    }

    componentDidMount() {
        let params = getURLParameters(this.props);

        if (params.readingLicenses && params.mathLicenses && params.erLicenses) {
            this.setState({
                readingLicenses: params.readingLicenses,
                mathLicenses: params.mathLicenses,
                erLicenses: params.erLicenses,
                discountCode: params.discount,
            });
            if (params.discount) {
                this.setState({
                    "discount-code": params.discount
                });
            }
        }

        let infoPromise = modelRequest.get('/api/account/info')

        modelRequest.get("/api/countries").then((countriesData) => {
            this.setState({
                countriesOptions: countriesData.countries
            }, async () => {
                let infoResponse = await infoPromise

                let setField = (field, value) => {
                    let domField = document.getElementById(field)
                    if (domField) {
                        domField.value = value
                    }
                }
                var { name, email, city, country, state, postalCode, phoneNumber } = infoResponse

                setField("city", city)
                setField("postal-code", postalCode)
                setField("contact-name", name)
                setField("contact-phone-number", phoneNumber)
                setField("contact-email-address", email)
                setField("country", country)
                setField("state-autofill", state)
                getStates(document.getElementById("country"))
                this.setState({ loading: false })
            })
        })

        this.setState({
            countriesOptions: countriesAndStates.countries //todo: replace with ajax call
        }, () => {
            // initialize scripts for form
            initializeLocations();
            initializeFormValidation(this.onSubmit);
        });

        getPriceData({
            math: params.mathLicenses,
            reading: params.readingLicenses,
            discountCode: params.discount
        }).then((response) => {
            this.setState({ price: response.price })
        })
    }

    onSubmit = () => {

        let getField = (field) => {
            let domField = document.getElementById(field)
            if (domField) {
                return domField.value
            }
        }

        this.setState({ loading: true, submitting: true })

        modelRequest.post("/api/subscription/purchase-order", {
            name: getField("contact-name"),
            address: getField("address"),
            city: getField("city"),
            state: getField("state"),
            postalCode: getField("postal-code"),
            country: getField("country"),
            emailAddress: getField("contact-email-address"),
            phone: getField("contact-phone-number"),
            school: getField("school"),
            district: getField("district"),
            price: this.state.price,
            readingLicenses: this.state.readingLicenses,
            mathLicenses: this.state.mathLicenses,
            erLicenses: this.state.erLicenses,
            purchaseOrderNumber: getField("purchase-order-number"),
            discountCode: this.state.discountCode
        }).then((res) => {
            redirect.send("/students", this.props, () => {
                message.success("Thank you for submitting a purchase order, we will get back with you shortly.")
            })
        }).catch((err) => {
            message.error(err)
        }).finally(() => this.setState({ loading: false, submitting: false }))
    }

    render() {
        return (
            <React.Fragment>
                <h1 className='text-center'>Subscribe to Great Leaps with a Purchase Order</h1>
                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Enter your purchase order information below, and we'll follow up with you shortly with more information.</p>
                    </div>
                </div>
                <form className="form-horizontal form-validate text-center" method="post">
                    <Row className="mb-3">
                        <label className="col-sm-3 control-label text-end">Reading Licenses</label>

                        <div className="col-sm-6">
                            <p className="form-control-static text-start text-center-xs">{this.state.readingLicenses}</p>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-sm-3 control-label text-end">Math Licenses</label>

                        <div className="col-sm-6">
                            <p className="form-control-static text-start text-center-xs">{this.state.mathLicenses}</p>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-sm-3 control-label text-end">Emergent Reader Licenses</label>

                        <div className="col-sm-6">
                            <p className="form-control-static text-start text-center-xs">{this.state.erLicenses}</p>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-sm-3 control-label text-end">Price</label>

                        <div className="col-sm-6">
                            <p className="form-control-static text-start text-center-xs">{"$" + this.state.price}</p>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="purchase-order-number" className="d-none d-sm-inline col-sm-3 control-label text-end">Purchase Order Number</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" disabled={this.state.loading} id="purchase-order-number" name="purchase-order-number" placeholder="Purchase Order Number" data-description="enter a purchase order number" required autoFocus />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="district" className="d-none d-sm-inline col-sm-3 control-label text-end">District</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="district" name="district" placeholder="District" maxlength="175" data-description="enter a district" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="school" className="d-none d-sm-inline col-sm-3 control-label text-end">School</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="school" name="school" placeholder="School" maxlength="175" data-description="enter a school" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="country" className="d-none d-sm-inline col-sm-3 control-label text-end">Country</label>

                        <div className="col-sm-6">
                            <select className="form-control" disabled={this.state.loading} name="country" id="country" required data-description="select a country">
                                <option value="" disabled selected>Country:</option>
                                {this.state.countriesOptions.map((item) => {
                                    return (<option value={item}>{item}</option>)
                                })}
                            </select>
                        </div>
                    </Row>
                    <div className="state-autofill-target">
                        <input type="text" name="state-autofill" disabled={this.state.loading} id="state-autofill" readOnly />
                    </div>
                    <Row className="mb-3">
                        <label for="state" className="d-none d-sm-inline col-sm-3 control-label text-end">Address</label>
                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="address" name="address" placeholder="Address" data-description="enter an address" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="state" className="d-none d-sm-inline col-sm-3 control-label text-end">State or Province</label>
                        <div className="col-sm-6">
                            <select className="form-control" disabled={this.state.loading} name="state" id="state" required data-description="select a state or province"></select>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="city" className="d-none d-sm-inline col-sm-3 control-label text-end">City</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="city" name="city" placeholder="City" maxlength="175" data-description="enter a city" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="postal-code" className="d-none d-sm-inline col-sm-3 control-label text-end">Postal Code</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="postal-code" name="postal-code" placeholder="Postal Code" maxlength="175" data-description="enter a postal code" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="contact-name" className="d-none d-sm-inline col-sm-3 control-label text-end">Contact Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="contact-name" name="contact-name" placeholder="Contact Name" maxlength="175" data-description="enter a contact name" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="contact-phone-number" className="d-none d-sm-inline col-sm-3 control-label text-end">Contact Phone Number</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="contact-phone-number" name="contact-phone-number" placeholder="Contact Phone Number" maxlength="175" data-description="enter a contact phone number" required />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label for="contact-email-address" className="d-none d-sm-inline col-sm-3 control-label text-end">Contact Email Address</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" disabled={this.state.loading} id="contact-email-address" name="contact-email-address" placeholder="Contact Email Address" maxlength="175" data-description="enter a contact email address" required />
                        </div>
                    </Row>
                    <button type="submit" className="btn btn-secondary btn-lg" disabled={this.state.loading}>
                        {this.state.submitting ?
                            <ClipLoader
                                size={20}
                                color={"#123abc"}
                                loading={this.state.submitting}
                            /> : "Submit"}
                    </button>
                </form>
            </React.Fragment>
        )
    }

}

export default PurchaseOrder;
